/* eslint-disable no-new */
/* eslint-disable no-unused-expressions */
import Vue from 'vue'
import store from './store'
import App from './App.vue'
import router from './router'
import ElementUI from 'element-ui'
import 'viewerjs/dist/viewer.css'
import VueViewer from 'v-viewer'
import { i18n } from './plugins/i18n/i18n'

import './permission'
import 'element-ui/lib/theme-chalk/index.css'
import locale from '@/config/element-locale'
import '@/plugins/vue-gtm'
import './assets/tailwind.css'
import ComponentsRegister from './components/index'
import '@/styles/github-markdown.css'
import logger from '@/utils/logger'
import { VueReCaptcha } from 'vue-recaptcha-v3'
import { GetSiteKey } from './api/public'
import { get } from 'lodash'
import('@/styles/global/index.scss')

const start = async () => {
  let siteKey
  const requireAll = requireContext => requireContext.keys().map(requireContext)
  const req = require.context('@/assets/svgIcon', true, /\.svg$/)
  requireAll(req)

  // fix autocomplete no show keyboard
  ElementUI.Select.computed.readonly = function () {
    const isIE = !this.$isServer && !Number.isNaN(Number(document.documentMode))
    if (!this.filterable) return true
    return !(this.filterable || this.multiple || !isIE) && !this.visible
  }

  Vue.config.productionTip = false
  Vue.prototype.$logger = logger
  Vue.use(ElementUI, { locale })
  Vue.use(ComponentsRegister)
  Vue.use(VueViewer)

  // recaptcha V3
  try {
    const res = await GetSiteKey()
    siteKey = res
    Vue.use(VueReCaptcha, { siteKey: get(siteKey, 'siteKeyV3') })
  } catch (error) {
    console.log(error)
  }

  new Vue({
    router,
    store,
    i18n,
    render: (h) => h(App),
  }).$mount('#app')

  store.commit('app/SET_SITEKEY', { version: 'v2', value: get(siteKey, 'siteKey') })
  store.commit('app/SET_SITEKEY', { version: 'v3', value: get(siteKey, 'siteKeyV3') })
}

start()
