export default [
  {
    // 一般登入
    path: '/login/:orgCode?',
    name: 'Login',
    component: () => import('@/views/Login/Login'),
    hidden: true,
  },
  {
    // 授權登入
    path: '/auth-login',
    name: 'AuthLogin',
    component: () => import('@/views/Login/AuthLogin'),
    hidden: true,
  },
  {
    // 忘記密碼
    path: '/forget-password',
    name: 'ForgetPassword',
    component: () => import('@/views/ForgetPassword/ForgetPassword.vue'),
  },
  {
    // 設定密碼
    path: '/set-password',
    name: 'SetPassword',
    component: () => import('@/views/SetPassword/SetPassword.vue'),
  },
  {
    // 重置密碼驗證
    path: '/reset-password/:forgetPasswordAuthnId/:code',
    name: 'ResetPassword',
    component: () => import('@/views/ResetPassword/ResetPassword.vue'),
  },
]
