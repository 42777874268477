<template>
  <div class="empty-block">
    <img
      width="100"
      style="margin: auto"
      src="@/assets/icon/nodata/no_data.svg"
      alt=""
      srcset=""
    >
    <span>{{ $t('common.table.empty2.text') }}</span>
  </div>
</template>

<script>
export default {
  name: 'EmptyBlock',
}
</script>

<style scoped lang="scss">
.empty-block {
  @apply flex flex-col w-full justify-center items-center leading-[30px] p-[20px] text-[14px];
  // display: flex;
  // width: 100%;
  // flex-direction: column;
  // justify-content: center;
  // align-items: center;
  // line-height: 30px;
  // padding: 20px;
  // font-size: 14px;
}
</style>
