import { GetMe, GetMeFeature } from '@/api/user'
// import { Message } from 'element-ui'
import ls from '@/utils/localstorage'
import router from '@/router'
import store from '@/store'
import { setUserProperties, userPropertiesConfig } from '@/use/useGtag'

const state = {
  user: null,
  userName: '',
  role: '',
  shop: null,
  allowShop: [],
  planFeature: null,
  planLimit: null,
  features: [],
}

const mutations = {
  SET_USER: (state, user) => {
    ls.setUser(user)
    state.user = user
  },
  SET_USER_NAME: (state, userName) => {
    state.userName = userName
  },
  SET_ROLES: (state, role) => {
    state.role = role
  },
  SET_PLAN: (state, plan) => {
    const { shopPlanFeature, shopPlanLimit } = plan
    state.planFeature = shopPlanFeature
    state.planLimit = shopPlanLimit
  },
  SET_NOW_SHOP: (state, shop) => {
    ls.setShop(shop)
    state.shop = shop
  },
  SET_ALLOW_SHOP: (state, allowShop) => {
    state.allowShop = allowShop
  },
  SET_FEATURES: (state, features) => {
    state.features = features
  },
}

const actions = {
  // get user info
  async getMe ({ commit, dispatch }) {
    const user = await GetMe()
    const { role, name, id, AuthEmail } = user
    const token = ls.getToken()

    setUserProperties(userPropertiesConfig.userId, id)
    setUserProperties(userPropertiesConfig.userEmail, AuthEmail.email)
    setUserProperties(userPropertiesConfig.userName, name)
    setUserProperties(userPropertiesConfig.roleType, role)

    commit('SET_USER', user)
    commit('SET_USER_NAME', name)
    commit('SET_ROLES', role)

    // ---- Set User View Shop
    await dispatch('checkOrgAdmin', user)

    // 記憶到快速登入帳號清單中
    await dispatch('storeAccount', { token, user })

    // ---- Set User View Shop
    // ---- 利用合約篩選可看到的模組
    const shop = ls.getShop()
    const { features, shopPlan, contract } = await dispatch('getFeature', {
      shopId: shop,
    })

    return {
      role,
      features,
      shopPlan,
      contract,
    }
  },
  // get user info
  async addAllowShop ({ state, commit }, { id, name, phone, address }) {
    const allowShop = state.allowShop
    allowShop.push({
      id,
      name,
      phone,
      address,
    })
    commit('SET_ALLOW_SHOP', allowShop)
  },
  async getFeature ({ commit, dispatch }, { shopId, message }) {
    // ---- Set User View Shop
    const features = await GetMeFeature()
    commit('SET_FEATURES', features)
    store.commit('permission/SET_RAW_PERMISSIONS', {
      key: 'userFeatures',
      data: { ...features },
    })
    store.commit('permission/SET_COMPUTED_PERMISSIONS', {
      key: 'userFeatures',
      data: { ...features },
    })

    // ---- 利用店家篩選可使用的權限
    let shopPlan = {
      shopPlanFeature: {
        'admin.*': true,
      },
      shopPlanLimit: {
        shopLimit: 1,
        appointmentUnitLimit: 0,
        appointmentReservationLimitPerMonth: 0,
        ecommerceProductLimit: 0,
        ecommerceCategoryLimit: 0,
      },
    }

    if (shopId) {
      // shopPlan = await dispatch('shop/getShopPlan', { shopId }, { root: true }) || shopPlan
      const res = await dispatch(
        'shop/getShopPlan',
        { shopId, message },
        { root: true },
      )
      const shopPlanFeatures = res.shopPlanFeature
      const shopPlanLimits = res.shopPlanLimit
      store.commit('permission/SET_RAW_PERMISSIONS', {
        key: 'shopPlanFeatures',
        data: { ...shopPlanFeatures },
      })
      store.commit('permission/SET_RAW_PERMISSIONS', {
        key: 'shopPlanLimits',
        data: { ...shopPlanLimits },
      })
      store.commit('permission/SET_COMPUTED_PERMISSIONS', {
        key: 'shopPlanFeatures',
        data: { ...shopPlanFeatures },
      })
      store.commit('permission/SET_COMPUTED_PERMISSIONS', {
        key: 'shopPlanLimits',
        data: { ...shopPlanLimits },
      })
      shopPlan = res
      if (!res) {
        router.push({ name: 'BranchShopSetting' })
        return {
          features,
          shopPlan: null,
        }
      }
      commit('SET_NOW_SHOP', shopId)
    }
    commit('SET_PLAN', shopPlan)

    return {
      features,
      shopPlan,
    }
  },
  async checkOrgAdmin ({ commit }, user) {
    const { Shops } = user
    // const shop = ls.getShop() || ( Shops.length > 0 ? Shops[0].id : null)
    commit('SET_ALLOW_SHOP', Shops)
    // commit('SET_NOW_SHOP', shop)
  },
  // eslint-disable-next-line no-empty-pattern
  storeAccount ({}, { token, user }) {
    const preStore = ls.getStoreAccount() || []
    const existIndex = preStore.findIndex(
      (item) =>
        `${item.user.OrgId}:${item.user.id}` === `${user.OrgId}:${user.id}`,
    )
    if (existIndex === -1) {
      preStore.push({
        token,
        user,
      })
    } else {
      preStore[existIndex] = {
        token,
        user,
      }
    }
    ls.setStoreAccount(preStore)
  },
  // eslint-disable-next-line no-empty-pattern
  deleteAccount ({}, { token }) {
    const preStore = ls.getStoreAccount() || []
    const findIndex = preStore.findIndex((item) => item.token === token)
    if (findIndex >= 0) {
      preStore.splice(findIndex, 1)
      ls.setStoreAccount(preStore)
    }
  },
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
}
