<template>
  <div class="tab-item" :class="{active: isActive}" @click="onTabChange(data)">
    <div class="tab-item-content">
      <!-- <el-icon><component :is="data.meta?.icon || IconFile" /></el-icon> -->
      <p v-if="isPinTab(data)">📌</p>
      <p>{{ $t(tabName(data)) }}</p>
      <div class="close-btn">
        <!-- <el-icon @click.stop="onClose(data)"><Component :is="IconX" /></el-icon> -->
        <button v-if="!isPinTab(data)" @click.stop="onClose(data)">
          <MaterialIcon color="var(--gray-80)" size="16">close</MaterialIcon>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { usePageTabs } from '@/use/usePageTabs'
import { get } from 'lodash'
// import { IconFile, IconX } from '@tabler/icons-vue'
import { computed, defineComponent } from 'vue'
import { useRouter } from 'vue-router/composables'

export default defineComponent({
  name: 'PageTabItem',
  props: {
    data: {
      type: Object,
      required: true,
      default: () => ({}),
    },
  },
  setup (props, { emit }) {
    const router = useRouter()
    const { state, goToTab, setActiveTab, closeTab, isPinTab } = usePageTabs()

    const onTabChange = (tab) => {
      if (tab === state.value.activeTab) return
      setActiveTab(tab)
      goToTab(tab, router)
    }

    const tabName = computed(() => (tab) => {
      return tab.meta.title || tab.name
    })

    const isActive = computed(() => get(props.data, 'name') === get(state.value, 'activeTab.name'))

    const onClose = (tab) => {
      closeTab(tab, router)
    }

    return {
      state,
      isActive,
      onTabChange,
      tabName,
      onClose,
      isPinTab,
    }
  },
})

</script>

<style lang="postcss" scoped>
.tab-item {
  @apply cursor-pointer border-[1px] border-primary-100 rounded-md px-[10px] py-[5px] text-gray-80;
  @apply hover:bg-primary-30;
}
.tab-item-content {
  @apply flex items-center gap-[8px];
}

.close-btn {
  @apply relative top-[1px] hover:bg-opacity-40;
  @apply transition duration-300 ease-in-out;
}

.active {
  @apply bg-primary-30;
}
</style>
