import router from './router'
import store from './store'
import { get } from 'lodash'
import { Message } from 'element-ui'
import ls from '@/utils/localstorage' // get token from cookie
import { getPageTitle, setFavicon } from '@/utils/helper' // no redirect whitelist
import { setOrgConfig } from './orgConfig'
import { keepAliveConfig } from '@/config/keepAlive'
import { loadLanguageAsync } from '@/plugins/i18n/i18n'
import Cookies from 'js-cookie'
const whiteList = [
  '/login',
  /^(\/read-member-history-file\/).+/,
]

function getModuleFromPath (path) {
  if (path.includes('/reservations')) return 'reservations'
  if (path.includes('/public-appointment')) return 'publicAppointment'
  return ''
}
function getBrowserLanguage () {
  const deviceLanguage = window.navigator.language.toLowerCase() // 瀏覽器語言
  let browserLanguage

  if (deviceLanguage === 'zh-tw') {
    browserLanguage = 'zh'
  } else if (deviceLanguage === 'ja') {
    browserLanguage = 'jp'
  } else if (deviceLanguage === 'ko') {
    browserLanguage = 'kr'
  } else {
    browserLanguage = 'en'
  }

  return browserLanguage
}
router.beforeEach(async (to, from, next) => {
  let browserLanguage = Cookies.get('old_locale') || window.navigator.language
  // console.log('🔥🔥🔥 browserLanguage', Cookies.get('old_locale'), window.navigator.language, browserLanguage)

  // Check if it's the first visit and if the org is a Japanese shop

  if (to.name === 'Login') { // 登入頁
    browserLanguage = getBrowserLanguage()
  } else {
    const isFirstVisit = !localStorage.getItem('visited')
    if (isFirstVisit) {
      browserLanguage = store.getters.isOverseasShop ? getBrowserLanguage() : 'zh'
      localStorage.setItem('visited', 'true')
    } else if (!store.getters.isOverseasShop) {
      browserLanguage = 'zh'
    }
  }

  await loadLanguageAsync(browserLanguage)

  console.log(to.params)
  console.group('page change')
  console.log('from', from.name)
  console.log('to', to.name)
  console.groupEnd()

  let appTheme = ''
  if (to.name !== from.name) store.commit('app/TOGGLE_PAGE_CHANGING', true)

  // TODO 預計棄用
  if (get(keepAliveConfig, `${to.name}`)) {
    store.commit('app/SET_KEEP_ALIVE', to.name)
  }

  if (!from.name) {
    appTheme = await setOrgConfig()

    // 特殊需求 - 特定頁面轉址 ??
    if (to.path.includes('/order-detail/')) {
      const transferPath = {
        path: to.path,
        query: to.query,
      }
      ls.setNeedTransfer(transferPath)
    }
  }

  // set page title
  const targetTheme = appTheme || store.getters.appTheme
  document.title = getPageTitle(targetTheme, to.meta.title)
  // set favicon
  setFavicon(targetTheme)

  // determine whether the user has logged in
  const hasToken = ls.getToken()
  const currentModule = getModuleFromPath(to.path)
  store.commit('app/SET_CURRENT_MODULE', currentModule)

  if (to.path === '/auth-login') return next()
  if (to.name === 'ForgetPassword') return next()
  if (to.name === 'ResetPassword') return next()
  if (to.name === 'SetPassword') return next()
  if (to.path === '/not-found') return next()
  if (hasToken) {
    // if is logged in, redirect to the home page
    if (to.path === '/login' || to.path === '/') {
      return next({ path: '/branch-shop-options' })
    } else {
      if (store.getters.shop && !get(store.state, 'shop.shopConfig.id')) {
        store.dispatch('shop/findShop', { shopId: store.getters.shop })
        store.dispatch('shop/getGlobalShopData', { shopId: store.getters.shop })
      }

      const hasRoles = store.getters.role
      if (hasRoles) {
        return next()
      } else {
        try {
          // get user info
          const { features, shopPlan } = await store.dispatch('user/getMe')

          const isTempPassword = get(store.state, 'user.user.AuthEmail.isTempPassword')
          if (isTempPassword) {
            return next({ path: '/set-password', query: { tempPedRest: true } })
          }

          // generate accessible routes map based on role
          const accessRoutes = await store.dispatch('permission/generateRoutes', { features, shopPlan })

          // dynamically add accessible routes
          accessRoutes.map(item => router.addRoute(item))

          // hack method to ensure that addRoutes is complete
          // set the replace: true, so the navigation will not leave a history
          next({ ...to, replace: true })
        } catch (error) {
          if (error === 503) return alert('系統維護中，將於維護作業完成後恢復相關功能')
          // remove token and go to Login page to re-Login
          await store.dispatch('auth/logout')
          await store.dispatch('user/deleteAccount', { token: hasToken })
          Message.error(error || 'Has Error')
          next('/login')
        }
      }
    }
  } else {
    /* has no token */
    const whiteUrl = whiteList.find(l => {
      return to.path.indexOf(l) !== -1 || to.fullPath.match(l)
    })
    if (whiteUrl) {
      // in the free Login whitelist, go directly
      next()
    } else {
      // other pages that do not have permission to access are redirected to the Login page.
      next('/login')
    }
  }
})
