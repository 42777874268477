import Layout from '@/layout/index.vue'

export default [
  {
    path: '/dashboard',
    component: Layout,
    children: [
      {
        path: '',
        component: () => import('@/views/Home'),
        name: 'Dashboard',
        hidden: true,
        meta: {
          title: '控制台',
          action: 'admin.*',
        },
      },

      {
        path: '/parameter-settings',
        name: 'ParameterSettings',
        component: () => import('@/views/Parameters/Parameters'),
        hidden: true,
        meta: {
          action: 'admin.lineConfig.page',
          title: '參數設置',
        },
      },
    ],
  },
  {
    path: '/account-config',
    component: Layout,
    children: [
      {
        path: '',
        name: 'AccountConfig',
        hidden: true,
        component: () => import('@/views/Basic/AccountConfig'),
        meta: {
          title: '帳號設定',
          disabledQrcodeReader: true,
        },
      },
    ],
  },
  // -- Not Found 404
  {
    path: '/not-found',
    name: 'NotFound',
    component: () => import('@/views/NotFound/NotFound'),
    hidden: true,
  },
]
