import { render, staticRenderFns } from "./BaseTable.vue?vue&type=template&id=787165ba&scoped=true"
import script from "./BaseTable.vue?vue&type=script&setup=true&lang=js"
export * from "./BaseTable.vue?vue&type=script&setup=true&lang=js"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "787165ba",
  null
  
)

export default component.exports