import { GetUserInfoSetting } from '@/api/org/org'
import { setUserProperties, userPropertiesConfig } from '@/use/useGtag'
import { get } from 'lodash'
const state = {
  org: {},
  userRegisterConfig: null,
  isOverseasShop: false,
}

const mutations = {
  SET_ORG: (state, org) => {
    state.org = org
    state.isOverseasShop = get(org, 'DashboardConfig.orgModulePermission.isOverseasShop', false)
    setUserProperties(userPropertiesConfig.orgId, org.id)
    setUserProperties(userPropertiesConfig.orgName, org.name)
  },
}

const actions = {
  async getUserInfoSetting ({ state }, orgId) {
    const [res, err] = await GetUserInfoSetting({
      orgId,
    })

    if (err) {
      if (err.status === 404) return
      return err
    }
    state.userRegisterConfig = res.fields
    return null
  },
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
}
