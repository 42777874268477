import configuration from '@/configuration'
import dayjs from '@/lib/dayjs'
import { titleConfig, faviconConfig } from '@/config/html-document'
import sanitizeHtml from 'sanitize-html'
import { get } from 'lodash'
import { computed } from 'vue'
import { i18n } from '@/plugins/i18n/i18n'

export const getRefsVue2 = (root, refName) => {
  const target = get(root.$refs, refName)
  if (!target) return undefined
  const child = get(target, '$children[0]')
  if (child) return child
  return target
}

export const getRefsVue3 = (target) => {
  if (!target) return undefined
  const child = get(target, '$children[0]')
  if (child) return child
  return target
}

export function timeout (ms) {
  return new Promise(resolve => setTimeout(resolve, ms))
}

export const plusZero = (num) => {
  return num < 10 ? `0${num}` : num
}

export const localeString = (num) => {
  if (!num || Number.isNaN(num)) return num
  return num.toLocaleString()
}

//= > 服點數轉換為時間 HH:MM
export const timesConvert = (number) => {
  number = number / 60
  const hour = Math.floor(number)
  const decpart = number - hour
  const minute = Math.ceil((decpart * 60).toFixed(1))
  const time = `${plusZero(hour)}:${plusZero(minute)}`

  return time
}

//= > 物件抽取陣列生成
export const extractList = (key, targetArray) => {
  if (targetArray.length === 0) return []
  const list = []
  targetArray.forEach((item) => {
    list.push(item[key])
  })

  return list
}

//= > 本地圖片上傳
export const imgLoad = (e) => {
  const input = e.target
  const image = {
    file: null,
    name: null,
    blob: null,
  }
  if (input.files && input.files[0]) {
    image.blob = URL.createObjectURL(input.files[0])
    var reader = new FileReader()
    reader.onload = (e) => {
      image.file = e.target.result
    }
    reader.readAsDataURL(input.files[0])
    image.name = input.files[0].name
  }

  return image
}

//= > status 翻譯
export const statusTranslate = (status) => {
  const statusDict = {
    cancel: i18n.t('reservationList.status.cancel.title'),
    wait: i18n.t('reservationList.status.wait.title'),
    complete: i18n.t('reservationList.status.complete.title'),
    absence: i18n.t('reservationList.status.absence.title'),
  }

  return statusDict[status] || 'N/A'
}

//= > 圖片網址
export const imgSrc = (size, image, defaultImg) => {
  if (image === null) {
    if (defaultImg) return defaultImg
    const noImg = require('@/assets/icon/img-placeholder.png')
    return noImg
  }

  const url = `${configuration('imgServerHost')}/display/resize/${size}x/${
    image.filename
  }`
  return url
}

//= > 圖片網址
export const subscriptionImgSrc = (image, defaultImg) => {
  if (image === null) {
    if (defaultImg) return defaultImg
    const noImg = require('@/assets/icon/img-placeholder.png')
    return noImg
  }

  const url = image.imageUrl
  return url
}

//= > 判斷空物件
export const isEmptyObj = (obj) => {
  if (Object.keys(obj).length === 0) return true
  if (Object.keys(obj).length > 0) return false
}

//= > 日期時間格式化
export const dateTimeFormat = (dateTime) => {
  const data = {
    date: dayjs(dateTime).format('YYYY-MM-DD'),
    time: dayjs(dateTime).format('HH:mm'),
    dateTime: dayjs(dateTime).format('YYYY-MM-DD HH:mm'),
  }
  return data
}

//= > 陣列切割分頁
export const sliceIntoChunks = (arr, chunkSize) => {
  const res = []
  for (let i = 0; i < arr.length; i += chunkSize) {
    const chunk = arr.slice(i, i + chunkSize)
    res.push(chunk)
  }
  return res
}

//= > 生成時段
export const generateTimes = (unit, offset = 0) => {
  const times = []
  const end = 1440
  let time = 0
  if (offset) time += offset
  while (time <= end) {
    times.push(timesConvert(time))
    time += unit
  }

  return times
}

//= > 生成時間
export const generateNumbers = (start, max, interval) => {
  const numbers = []
  let count = start

  while (count < max) {
    numbers.push(count)
    count += interval
  }

  return numbers
}

export const orEmptyText = (data) => {
  if (!data) return undefined
  return data.trim()
}

/***
 * 改變Title Name
 * @param pageTitle
 * @returns {string}
 */
export function getPageTitle (appTheme, pageTitle) {
  const title = titleConfig[appTheme]
  if (pageTitle) {
    return `${i18n.t(title)} - ${i18n.t(pageTitle)}`
  }
  return `${title}`
}

export const getKeyByValue = (object, value) => {
  return Object.keys(object).find((key) => object[key] === value)
}

export const passValue = (data) => {
  return JSON.parse(JSON.stringify(data))
}

export const setFavicon = (appTheme) => {
  if (appTheme === 'ohbot' || appTheme === 'beauty') {
    const faviconElement =
      document.querySelector('link[rel="icon"]') ||
      document.createElement('link')
    faviconElement.rel = 'icon'
    faviconElement.type = 'image/x-icon'
    faviconElement.href = faviconConfig[appTheme]
    document.getElementsByTagName('head')[0].appendChild(faviconElement)
  } else if (['righttime', 'wishmobile', 'salesOhbot', 'xuanien'].includes(appTheme)) {
    const faviconElement =
      document.querySelector('link[rel="icon"]') ||
      document.createElement('link')
    faviconElement.rel = 'icon'
    faviconElement.type = 'image/png'
    faviconElement.href = faviconConfig[appTheme]
    document.getElementsByTagName('head')[0].appendChild(faviconElement)
  }
}

export const convertEmptyToUndefined = (value) => (!value ? undefined : value)

export const getAllDataFromApi = async (total, apiFunc, payload, arrayRes) => {
  const data = []
  while (data.length < total) {
    let result = []
    let error
    if (arrayRes) {
      const [res, err] = await apiFunc(payload)
      if (res) result = res
      if (err) error = err
    } else {
      result = await apiFunc(payload)
    }
    if (arrayRes && error) return [null, error]
    if (result.length === payload.limit) data.push(...result)
    if (!result.length) {
      if (arrayRes) return [data, null]
      return data
    }
    if (result.length < payload.limit) {
      data.push(...result)
      if (arrayRes) return [data, null]
      return data
    }
    payload.start += payload.limit
  }
  if (arrayRes) return [data, null]
  return data
}

export const cleanHtml = (source, allowedTags = ['img', 'ul', 'ol', 'li', 'h1', 'h2', 'h3', 'div', 'p', 'span', 'a', 'br', 'hr']) => {
  const res = sanitizeHtml(source, {
    allowedTags,
    allowedAttributes: false,
  })
  return res
}

export const downloadURI = (uri, name, newPage) => {
  let link = document.createElement('a')
  link.download = name
  link.href = uri
  if (newPage) link.target = '_blank'
  document.body.appendChild(link)
  link.click()
  document.body.removeChild(link)
  link = null
}

export const timeToNumber = (time) => {
  if (!time) return 0
  const [hour, min] = time.split(':')
  return Number(hour) * 60 + Number(min)
}

export const showErrorMsg = (that, err) => {
  if (err) {
    that.$message.error(err)
    return true
  }
  return false
}

export const ellipsisText = (str, maxLength = 20) => {
  if (str.length <= maxLength) return str
  return `${str.substring(0, maxLength)}...`
}

export const getCssVar = (varName) => {
  return getComputedStyle(document.documentElement).getPropertyValue(
    `--${varName}`,
  )
}

export const sortOrder = (list, orderKey = 'order') => {
  const logic = (a, b) => {
    if (a[orderKey] < b[orderKey]) return -1
    if (a[orderKey] > b[orderKey]) return 1
  }
  return list.sort(logic)
}

export const computedData = computed(() => {
  return (source, path) => {
    return get(source, path)
  }
})

export const isUUID = (uuid) => {
  const uuidRegex = /^[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}$/i
  return uuidRegex.test(uuid)
}
