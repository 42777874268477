export default [
  {
    path: '/test',
    name: 'Test',
    component: () => import('@/views/test/Test.vue'),
    hidden: true,
  },
  {
    name: 'Playground',
    path: '/playground',
    component: () => import('@/views/test/playground.vue'),
  },
]
